import React from 'react';
import './Services.css';
import fabricImage from './service-image-1.jpg'; // Update with correct path to your image
import samples from './samples.jpg';
import fabric_rolls from './fabric_rolls.jpg';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';

function Services() {
  const { t } = useTranslation();

  return (
    <div>
      {/* Existing Services Section */}
      <section className="services-page-section">
        <div className="services-content">
          <h1>{t('services.pageTitle')}</h1>
        </div>
      </section>

      {/* New Section: Czym się zajmujemy */}
      <section className="services-what-we-do-section">
        <div className="services-what-we-do-content">
          <h2>{t('services.whatWeDoTitle')}</h2>
          <p>{t('services.whatWeDoDescription')}</p>
        </div>
        <div className="services-what-we-do-image">
          <img src={fabricImage} alt="Fabric Stack" />
        </div>
      </section>

      {/* New Section: Sprzedaż */}
      <section className="services-sale-section">
        <div className="services-sale-content">
          <h2>{t('services.saleTitle')}</h2>
          <p>{t('services.saleDescription')}</p>
        </div>
        <div className="services-sale-image">
          <img src={fabric_rolls} alt="Fabric Sale Image" />
        </div>
      </section>

      {/* New Section: Darmowe próbki */}
      <section className="services-sample-section">
        <div className="services-sample-image">
          <img src={samples} alt="Free Samples Image" />
        </div>
        <div className="services-sample-content">
          <h2>{t('services.sampleTitle')}</h2>
          <p>{t('services.sampleDescription')}</p>
          <a href="contact" className="contact-button">
            {t('services.contactButtonText')}
            <span className="arrow">→</span>
          </a>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
